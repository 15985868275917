import React, { ReactElement, FunctionComponent, useRef } from 'react'
import {
  Box,
  Container,
  Grid,
  Text,
  Icon,
  Svg,
  Link,
  BoxProps,
} from '@mindfulchefuk/design-system'
import Illustration from '@mindfulchefuk/design-system/Illustrations/Illustrations'

import Roundel from '@mindfulchefuk/design-system/Icon/Roundel'
import StackedSmallLogo from '@mindfulchefuk/design-system/Logos/StackedSmall'
import { GoustoLogo } from '@mindfulchefuk/features/Homepage/components/logos/GoustoLogo'
import { HelloFreshLogo } from '@mindfulchefuk/features/Homepage/components/logos/HelloFreshLogo'
import { MindfulChefLogo } from '@mindfulchefuk/design-system/Logos/MindfulChefLogo'

import { ANALYTICS_CATEGORIES } from '@mindfulchefuk/constants'
import { logEvent } from '@mindfulchefuk/utils/analytics'
import { GreenChefLogo } from '@mindfulchefuk/features/Homepage/components/logos/GreenChefLogo'

const COMPARISON_TABLE_SOURCE = process.env.NEXT_PUBLIC_COMPARISON_TABLE_SOURCE

export const ComparisonTable = (): ReactElement => {
  const comparisonTableContainerRef = useRef(null)

  return (
    <Box
      id="comparison-table"
      as="section"
      my={32}
      position="relative"
      ref={comparisonTableContainerRef}
    >
      <Text
        variant={{ base: 'heading-sm', md: 'heading-md' }}
        as="h1"
        textAlign="center"
        mb={16}
        px={36}
      >
        We believe the quality of the food (really) matters
      </Text>

      <Text
        variant={{ base: 'body-md', sm: 'body-lg' }}
        as="p"
        textAlign="center"
        mb={32}
        px={36}
      >
        That&apos;s why 66% of our customers have switched from another recipe
        box company
      </Text>

      <Container position="relative" top={{ xl: 24 }} maxWidth="800px">
        <Grid
          templateColumns="2fr 1fr 1fr 1fr 1fr"
          columnGap={[8, 16]}
          alignItems="center"
          justifyContent="center"
        >
          <Box position="relative" left="20%" bottom={14}>
            <WhyUsPointer />
          </Box>
          <StackedSmallLogo
            width="100%"
            mx="auto"
            maxWidth={40}
            display={['inline-block', 'none']}
          />
          <MindfulChefLogo
            width={112}
            mx="auto"
            display={['none', 'inline-block']}
          />
          <HelloFreshLogo />
          <GoustoLogo width={[40, 112]} mx="auto" />
          <GreenChefLogo width={[32, 112]} mx="auto" />
        </Grid>
      </Container>
      <Box display="flex" marginBottom="-1px" maxHeight={48}>
        <ComparisonTableWaveTop />
      </Box>
      <Box backgroundColor="aubergine">
        <Container maxWidth="800px">
          <Grid
            data-testid="comparison-table-grid"
            templateColumns="2fr 1fr 1fr 1fr 1fr"
            columnGap={[8, 16]}
            rowGap={12}
            alignItems="center"
            justifyContent="center"
            color="white"
            overflow="none"
            maxHeight="none"
          >
            <Row>100% British & Free Range Chicken</Row>
            <Row>No refined carbs like white pasta, bread or white rice</Row>
            <Row>Every box bought donates one meal to a child in poverty</Row>
            <Row>All recipes gluten-free</Row>
            <Row gousto>1-person options</Row>
            <Row>Committed to Net Zero by 2030</Row>
            <Row>Investing in regenerative farming practices</Row>
            <Box gridColumn={1}>
              <Text variant={{ base: 'body-sm', sm: 'body-lg' }} mb={32}>
                <Link
                  maxWidth={256}
                  textAlign="left"
                  color="white"
                  href={COMPARISON_TABLE_SOURCE}
                  rel="noopener noreferrer nofollow"
                  target="_blank"
                  onClick={() => {
                    logEvent({
                      category: ANALYTICS_CATEGORIES.homepage,
                      action: 'Comparison table clicked',
                      label: `View source link clicked`,
                    })
                  }}
                >
                  Click here for source
                </Link>
              </Text>
            </Box>
          </Grid>
        </Container>
      </Box>
      <Box display="flex" marginTop="-1px" maxHeight={48}>
        <ComparisonTableWaveBottom />
      </Box>
      <Box
        position="absolute"
        top="0px"
        left="50%"
        width="100%"
        maxWidth="800px"
        transform="translateX(-50%)"
        height="100%"
        pointerEvents="none"
      >
        <Illustration
          type="chopped-red-pepper-4"
          maxWidth={{ base: '50px', sm: '65px' }}
          bottom={{ base: '-35px', sm: '-1.45vw' }}
          right={{ base: '10px' }}
        />
        <Illustration
          type="chickpea-cluster"
          display={{ base: 'none', sm: 'block' }}
          maxWidth={{ base: '160px', sm: 'none' }}
          top={{ base: '116px', sm: '90px', md: '88px', lg: '100px' }}
          left={{ base: '-112px', md: '-160px' }}
        />
      </Box>
    </Box>
  )
}

interface RowProps {
  children: string
  helloFresh?: boolean
  gousto?: boolean
  greenChef?: boolean
}

const Row: FunctionComponent<RowProps> = ({
  children: rowText,
  helloFresh,
  gousto,
  greenChef,
}) => (
  <>
    <Box gridColumn="1">
      <Text
        maxWidth={256}
        variant={{ base: 'body-sm', sm: 'body-lg' }}
        textAlign="left"
      >
        {rowText}
      </Text>
    </Box>
    <Tick gridColumn={2} />
    {/** MC always has a tick because we're the best */}
    {helloFresh ? <Tick gridColumn={3} /> : <Cross />}
    {gousto ? <Tick gridColumn={4} /> : <Cross />}
    {greenChef ? <Tick gridColumn={5} /> : <Cross />}
  </>
)

const Tick = (props?: BoxProps): ReactElement => (
  <Box py={12} {...props}>
    <Roundel type="tick" background="broccoli" mx="auto" size={[28, 40]} />
  </Box>
)

const Cross = (): ReactElement => (
  <Box py={12}>
    <Roundel
      type="thick-cross"
      background="tomato-300"
      mx="auto"
      size={[28, 40]}
    />
  </Box>
)

const WhyUsPointer = (): ReactElement => (
  <Box textAlign="center" display="inline-block" color="aubergine">
    <Text variant={{ base: 'body-md-500', sm: 'body-xxl-500' }} mb={4}>
      Why us?
    </Text>
    <Icon
      mx="auto"
      type="arrow-curved"
      size={32}
      strokeWidth={{ base: '.75px', sm: '1px' }}
      transform="rotate(-90deg)"
    />
  </Box>
)

const ComparisonTableWaveTop = (): ReactElement => (
  <Svg width="100%" viewBox="0 0 2390 79" preserveAspectRatio="none">
    <path
      d="M2390 79.5V17L1494.15 54.4015C1253.39 64.453 1012.21 56.4343 772.657 30.4128L726.142 25.3602C485.009 -0.832351 242.084 -6.43756 0 8.60529V79.5H2390Z"
      fill="currentColor"
    />
  </Svg>
)

const ComparisonTableWaveBottom = (): ReactElement => (
  <Svg width="100%" viewBox="0 0 2390 98" preserveAspectRatio="none">
    <path
      d="M2390 75.0531V0H0V66.7275C237.307 45.6096 475.924 43.4919 713.568 60.3945L1098.07 87.7423C1283 100.896 1468.66 100.186 1653.48 85.6186L2000.84 58.2413C2040.9 55.0845 2081.12 54.8183 2121.21 57.4447L2390 75.0531Z"
      fill="currentColor"
    />
  </Svg>
)
