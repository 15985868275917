import React, { ReactElement } from 'react'

import {
  Hero,
  WeeklyRecipes,
  OneFeedsTwoStats,
  Partnerships,
  BottomPageCTASection,
  TrustpilotCarousel,
  TrustPilotMicroCombo,
  EmailCapture,
  ComparisonTable,
  HomepageLayout,
} from '@mindfulchefuk/features/Homepage/components'

import {
  HomepagePromotionalBanner,
  MultiCardGroup,
  ProductAnnouncementModal,
} from '@mindfulchefuk/features/CMS/components'

import { Box } from '@mindfulchefuk/design-system'
import { useApplyVoucher } from '@mindfulchefuk/features/Basket'
import { useInstrumentHomepageViewed } from '@mindfulchefuk/utils/analytics/amplitude/instrument/hooks'
import { useCMSHomepageVariant } from '@mindfulchefuk/features/CMS/hooks/useCMSHomepageVariant'

interface Props {
  voucherCode?: string
}

export const Homepage = ({ voucherCode }: Props): ReactElement => {
  useInstrumentHomepageViewed()
  useApplyVoucher(voucherCode)
  const { howItWorks, trustPilotCarouselTitle } = useCMSHomepageVariant()

  return (
    <>
      <HomepageLayout>
        <Box color="aubergine">
          <Hero />
          <ComparisonTable />

          <TrustpilotCarousel
            ctaLocation="homepage trustpilot CTA"
            title={trustPilotCarouselTitle}
          />

          <HomepagePromotionalBanner />

          <WeeklyRecipes
            title="There's something for everyone"
            titleEmphasis="something for everyone"
            subtitle="Choose from 20 delicious recipes each week."
            subtitleEmphasis="High in protein, no refined sugars and gluten free"
            ctaLocation="homepage weekly recipe CTA"
            waveIllustrationWrapper={{
              bgColor: 'salt',
            }}
          />

          <MultiCardGroup
            data={howItWorks}
            ctaLocation="homepage how it works CTA"
            useCms
            waveIllustrationWrapper={{
              bgColor: 'white',
              illustrations: {
                topRight: {
                  type: 'inline-spinach-chilli-slice',
                  styles: {
                    right: {
                      base: '-132px',
                      sm: '-64px',
                    },
                    top: {
                      base: '46px',
                      sm: '64px',
                    },
                  },
                },
                bottomLeft: {
                  type: 'inline-tomato-slice',
                  styles: {
                    left: {
                      base: '-114px',
                      sm: '-60px',
                    },
                    top: {
                      base: '550px',
                      sm: '550px',
                    },
                  },
                },
              },
            }}
          />

          <OneFeedsTwoStats />

          <TrustPilotMicroCombo />

          <Partnerships />

          <BottomPageCTASection ctaLocation="homepage bottom of the page CTA" />

          <EmailCapture />
        </Box>
      </HomepageLayout>

      <ProductAnnouncementModal page="page-homepage" />
    </>
  )
}
